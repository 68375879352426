<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Perlu Kamu Tahu</span>
        <p class="text-center">
          Badan Penyelenggara Jaminan Produk Halal (BPJPH) adalah sebuah badan
          yang terbentuk dibawah naungan Kementerian Agama
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container pad-20">
        <div class="text-left">
          <div class="row">
            <template v-for="(data, i) in info">
              <div class="col-md-6 for-all-info" :key="i">
                <div class="item-sec">
                  <img :src="data.background" alt="" @click="redirectTo(data)" />
                  <div class="for-content" v-if="data.title" @click="redirectTo(data)">
                    <h2>{{ data.title }}</h2>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="mt-3 no-last style_me"> 
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: [],
      rows: 0,
      perPage: 8,
      currentPage: 1,
    };
  },
  watch: {
    currentPage() {
      this.getInfo()
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.axios.get('need-to-know', {
        params: {
          status: 1,
          page: this.currentPage,
          limit: this.perPage,
          sortBy: 'created_at',
          sortType: 'DESC'
        }
      })
        .then( response => {
          this.info = response.data.result.knows
          this.rows = response.data.result.total
        })
    },
    redirectTo(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.location = info.file
      } else if (info.page) {
        this.$router.push(`/page/${info.page.slug}`)
      }
    }
  }
};
</script>